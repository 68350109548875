import {
  createRouter,
  createWebHashHistory,
  createMemoryHistory,
} from "vue-router";
import Cookies from "js-cookie";
import * as Api from "@/utils/api";

const routes = [
  // {
  //   path: '/',
  //   name: 'login',
  //   component: import('../views/login.vue')
  // },
  // {
  // 	path: '/',
  // 	redirect: {
  // 		name: 'login'
  // 	}
  // },
  {
    path: "/",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/new_file",
    name: "new_file",
    component: () => import("../views/new_file.vue"),
  },
  {
    path: "/dwon",
    name: "dwon",
    component: () => import("../views/dwon.vue"),
  },
  {
    path: "/knowWord",
    name: "knowWord",
    component: () => import("../views/knowWord.vue"),
  },
  {
    path: "/share",
    name: "share",
    component: () => import("../views/share.vue"),
  },
  {
    path: "/",
    name: "main",
    component: () => import("../views/main.vue"),
    children: [
      {
        path: "/personalCenter",
        name: "personalCenter",
        meta: {
          title: "个人中心",
        },
        component: () => import("../views/personalCenter.vue"),
      },
    ],
    // children: [
    //   {
    //     path: "/moniLargeScreen",
    //     name: "moniLargeScreen",
    //     meta: {
    //       title: "首页概况",
    //     },
    //     component: () => import("../views/moniLargeScreen/index.vue"),
    //   },

    //   {
    //     path: "/caseMange",
    //     name: "caseMange",
    //     meta: {
    //       title: "接待登记",
    //     },
    //     component: () => import("../views/caseMange/index.vue"),
    //   },
    //   {
    //     path: "/deviceMange",
    //     name: "deviceMange",
    //     meta: {
    //       title: "设备管理",
    //     },
    //     component: () => import("../views/deviceMange/index.vue"),
    //   },
    //   {
    //     path: "/remoteMeeting",
    //     name: "remoteMeeting",
    //     meta: {
    //       title: "远程会见",
    //     },
    //     component: () => import("../views/remoteMeeting/index.vue"),
    //   },
    //   {
    //     path: "/printingservices",
    //     name: "printingservices",
    //     meta: {
    //       title: "文印服务",
    //     },
    //     component: () => import("../views/printingservices/index.vue"),
    //   },
    //   {
    //     path: "/autonomousinquiry",
    //     name: "autonomousinquiry",
    //     meta: {
    //       title: "自主查询",
    //     },
    //     component: () => import("../views/autonomousinquiry/index.vue"),
    //   },
    //   {
    //     path: "/detainManage",
    //     name: "detainManage",
    //     meta: {
    //       title: "待办事项",
    //     },
    //     component: () => import("../views/detainManage/index.vue"),
    //   },
    //   {
    //     path: "/lawyerservices",
    //     name: "lawyerservices",
    //     meta: {
    //       title: "律师服务",
    //     },
    //     component: () => import("../views/lawyerservices/index.vue"),
    //   },
    //   {
    //     path: "/writtenacceptance",
    //     name: "writtenacceptance",
    //     meta: {
    //       title: "文字签收",
    //     },
    //     component: () => import("../views/writtenacceptance/index.vue"),
    //   },
    //   {
    //     path: "/intelligentservicecabinet",
    //     name: "intelligentservicecabinet",
    //     meta: {
    //       title: "智能服务柜",
    //     },
    //     component: () => import("../views/intelligentservicecabinet/index.vue"),
    //   },
    //   {
    //     path: "/receptionregistration",
    //     name: "receptionregistration",
    //     meta: {
    //       title: "接待登记",
    //     },
    //     component: () => import("../views/receptionregistration/index.vue"),
    //   },
    //   {
    //     path: "/hearingservices",
    //     name: "hearingservices",
    //     meta: {
    //       title: "听证服务",
    //     },
    //     component: () => import("../views/hearingservices/index.vue"),
    //   },
    //   {
    //     path: "/confessionofguiltandpunishment",
    //     name: "confessionofguiltandpunishment",
    //     meta: {
    //       title: "认罪认罚",
    //     },
    //     component: () =>
    //       import("../views/confessionofguiltandpunishment/index.vue"),
    //   },
    //   {
    //     path: "/remotereception",
    //     name: "remotereception",
    //     meta: {
    //       title: "远程接待",
    //     },
    //     component: () => import("../views/remotereception/index.vue"),
    //   },
    //   {
    //     path: "/lawyeronduty",
    //     name: "lawyeronduty",
    //     meta: {
    //       title: "律师值班",
    //     },
    //     component: () => import("../views/lawyeronduty/index.vue"),
    //   },
    //   {
    //     path: "/videoreporting",
    //     name: "videoreporting",
    //     meta: {
    //       title: "视频举报",
    //     },
    //     component: () => import("../views/videoreporting/index.vue"),
    //   },

    //   {
    //     path: "/businessWork",
    //     name: "businessWork",
    //     meta: {
    //       title: "业务工作台",
    //     },
    //     component: () => import("../views/businessWork/index.vue"),
    //   },

    //   {
    //     path: "/caseHandleTime",
    //     name: "caseHandleTime",
    //     meta: {
    //       title: "办案时长统计",
    //     },
    //     component: () =>
    //       import("../views/statisticalPlat/caseHandleTime/index.vue"),
    //   },

    //   {
    //     path: "/user",
    //     name: "user",
    //     meta: {
    //       title: "用户管理",
    //     },
    //     component: () => import("../views/systemMange/user/index.vue"),
    //   },
    //   {
    //     path: "/dictionaries",
    //     name: "dictionaries",
    //     meta: {
    //       title: "字典管理",
    //     },
    //     component: () => import("../views/systemMange/dictionaries/index.vue"),
    //   },
    //   {
    //     path: "/journal",
    //     name: "journal",
    //     meta: {
    //       title: "日志管理",
    //     },
    //     component: () => import("../views/systemMange/journal/index.vue"),
    //   },

    //   {
    //     path: "/menu",
    //     name: "menu",
    //     meta: {
    //       title: "菜单管理",
    //     },
    //     component: () => import("../views/systemMange/menu/index.vue"),
    //   },
    //   {
    //     path: "/person",
    //     name: "person",
    //     meta: {
    //       title: "角色管理",
    //     },
    //     component: () => import("../views/systemMange/person/index.vue"),
    //   },
    //   {
    //     path: "/unit",
    //     name: "unit",
    //     meta: {
    //       title: "单位管理",
    //     },
    //     component: () => import("../views/systemMange/unit/index.vue"),
    //   },
    //   {
    //     path: "/department",
    //     name: "department",
    //     meta: {
    //       title: "部门管理",
    //     },
    //     component: () => import("../views/systemMange/department/index.vue"),
    //   },
    //   {
    //     path: "/personalCenter",
    //     name: "personalCenter",
    //     meta: {
    //       title: "个人中心",
    //     },
    //     component: () => import("../views/personalCenter.vue"),
    //   },
    //   {
    //     path: "/lawyerAudit",
    //     name: "lawyerAudit",
    //     meta: {
    //       title: "认证审核",
    //     },
    //     component: () => import("../views/lawyer/audit/index.vue"),
    //   },
    //   {
    //     path: "/lawyerManage",
    //     name: "lawyerManage",
    //     meta: {
    //       title: "律师管理",
    //     },
    //     component: () => import("../views/lawyer/manage/index.vue"),
    //   },
    // ],
  },
  // {
  // 	path: "/:catchAll(.*)", // 不识别的path自动匹配404
  // 	redirect: '/404',
  // }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// 路由守卫,动态获取菜单路由
let registerRouteFresh = true;
router.beforeEach(async (to, from, next) => {
  const token = Cookies.get("token");
  if (!token) return next();
  if (token) {
    if (registerRouteFresh) {
      const res = await Api.getUserRoleMenu();
      if (res.IsSucceed == true) {
        console.log("res.result", res.result);
        res.result.forEach((v) => {
          if (
            (v.path == null || v.path == "" || v.path == "/") &&
            v.children.length > 0
          ) {
            v.children.forEach((a) => {
              router.addRoute("main", {
                path: a.path,
                component: () => import("../views" + a.component),
              });
            });
          } else {
            router.addRoute("main", {
              path: v.path,
              component: () => import("../views" + v.component),
            });
          }
        });
        router.addRoute("main", {
          path: "/404",
          name: "404",
          meta: {
            title: "404",
          },
          component: () => import("../views/404.vue"),
        });
        router.addRoute({
          path: "/:catchAll(.*)", // 此处需特别注意置于最底部
          redirect: "/404",
        });
        next({
          ...to,
          replace: true,
        });
        registerRouteFresh = false;
      }
    }
  }
  if (!registerRouteFresh) {
    if (to.path === "/login" || "/") return next();
    else return next();
  }
});

export default router;
