import http from './http';

const PRE_FIX = process.env.VUE_APP_BASE_API+process.env.VUE_APP_BASE_URL

function handleParams(url) {
    return PRE_FIX + url;
}
//跳过账号登录
export function Tglogin() {
    return http.post(PRE_FIX +'app/LoginDefault')
}
// 文件下载接口 
export function getFiledata (params) {
    return http.post(PRE_FIX + 'Common/RequestDownloadFile'+'?filename='+params)
}

//删除导出文件
export function deletDataliu (params) {
    return http.get(PRE_FIX + 'Common/RequestDeleteFile',params)
}



//内外网 加密文件转base  多条解析
export function getDecrypts (params) {
    return http.post(PRE_FIX + 'Common/GetImageFiles',params)
}


//内外网 加密文件转base  单条解析
export function getDecrypt (params) {
    return http.get(PRE_FIX + 'Common/GetImageFile',params)
}



// 内网删除分配
export function deleteLayeryjdata(params) {
    return http.post(PRE_FIX + 'Lawyer/DeleteLawyer', params)
}



// 内网回传律师事务所信用代码
export function getlscodeData (params) {
    return http.get(PRE_FIX + 'Lawyer/ChangeSccCodeByLawyer',params)
}


// 内网查询下拉list 律师
export function getlsallData (params) {
    return http.get(PRE_FIX + 'Lawyer/GetLawyerByUnitCodeWithFilter',params)
}

//内网修改图片
export function editLsjpg (params) {
    return http.post(PRE_FIX + 'system/EditLawyerDepartmentPhoto', params)
}

//内网修改图片
export function editLspng (params) {
    return http.post(PRE_FIX + 'system/EditLawyerIdentityphot',params)
}


//内网增加预约信息
export function addLayerdata(params) {
    return http.post(PRE_FIX + 'Lawyer/AddLawyerInNet', params)
}



//内网修改预约信息
export function editLawyernwdata(params) {
    return http.post(PRE_FIX + 'Lawyer/UpdateLawyer', params)
}


//最终上传2.0接口
export function getendIddata (params) {
    return http.get(PRE_FIX + 'Lawyer/UploadDataToTwentyfourSys', params)
}


//内网对比当事人信息
export function getpersonData (params) {
    return http.get(PRE_FIX + 'Lawyer/QueryDsrList', params)
}


//内网对比律师信息
export function getplsData (params) {
    return http.get(PRE_FIX + 'Lawyer/QueryLsList', params)
}




//内网导入律师数据
export function getlayerData (params) {
    return http.get(PRE_FIX + 'Lawyer/ImportLawyerArchivesInfo', params)
}

//外网导出律师数据
export function exportLawyerdata(params) {
    return http.post(PRE_FIX + 'Lawyer/ExportLawyerArchivesInfo', params)
}


//律师库导入
export function getlsdata (params) {
    return http.get(PRE_FIX + 'Lawyer/ImportLawyerData', params)
}

//律师阅卷状态是否禁用
export function getlsstate (params) {
    return http.get(PRE_FIX + 'Lawyer/QueryLawyerReservedInfo', params)
}

//卷宗类型分类统计
export function getTypelist (params) {
    return http.get(PRE_FIX + 'Lawyer/CountByCaseType', params)
}

//律师阅卷统计
export function getLawyerAlist (params) {
    return http.get(PRE_FIX + 'Lawyer/CountByLawyer', params)
}

//阅卷登记情况近7天
export function getTimelist (params) {
    return http.get(PRE_FIX + 'Lawyer/CountRegistSevendays', params)
}



//获取首页4个参数
export function getdataresList (params) {
    return http.get(PRE_FIX + 'Lawyer/Countinfo', params)
}



//根据卷宗id获取卷宗信息
export function getLawyerdatalist (params) {
    return http.get(PRE_FIX + 'Lawyer/QueryArchiveByIdPC', params)
}


//禁用律师
export function deleteLayerdata(params) {
    return http.post(PRE_FIX + 'Lawyer/DisableLawyer', params)
}


//修改律师
export function editLawyer(params) {
    return http.post(PRE_FIX + 'Lawyer/EditLawyer', params)
}

//作废阅卷记录
export function deleteLawyer(params) {
    return http.post(PRE_FIX + 'Lawyer/DeleteLawyerArchives', params)
}

//新增阅卷信息
export function addLawyerlist(params){
    return http.post(PRE_FIX + 'Lawyer/AddLawyerArchives', params)
}


//查询阅卷记录
export function getLawyerlists (params) {
    return http.get(PRE_FIX + 'Lawyer/QueryLawyerArchives', params)
}

//上传树状id
export function addTreelist(params){
    return http.post(PRE_FIX + 'Lawyer/AddArchivesInfoByChildId', params)
}

//新增律师
export function addLawyer(params){
    return http.post(PRE_FIX + 'Lawyer/AddLawyerInfo', params)
}


//查询律师下拉
export function getLsList (params) {
    return http.get(PRE_FIX + 'Lawyer/GetAllLawyerByUnitCode', params)
}



//修改密码

export function editUserPassword(params){
    return http.post(PRE_FIX + 'system/UpdatePass', params)
}

//认罪认罚
export function getRzrfList(params){
    return http.post(PRE_FIX + 'reception/QueryConfession', params)
}
//文书签收
export function getTextList(params){
    return http.post(PRE_FIX + 'Document/QueryDocument', params)
}
// xhj 2024-3-12云柜管理、云柜存储记录
//云柜管理分页查询
export function getCloudlist(params){
    return http.post(PRE_FIX + 'CloudCabinetmManagement/Querycabinet',params)
}
//新增云柜
export function getAddCloud(params){
    return http.post(PRE_FIX + 'CloudCabinetmManagement/Addcabinet',params)
}
//编辑云柜
export function getupdetCloud(params){
    return http.post(PRE_FIX + 'CloudCabinetmManagement/Updatecabinet',params)
}
//删除云柜
export function deletCloud(params){
    return http.post(PRE_FIX + 'CloudCabinetmManagement/Deletecabinet',params)
}
//获取云柜信息
export function getCloudall(params){
    return http.get(PRE_FIX + 'CloudCabinetmManagement/Notusedcabinetweb',params)
}
//云柜存储分页查询
export function isgetCloudlist(params){
    return http.post(PRE_FIX + 'AccessRecords/Querycabinet',params)
}
//云柜存储新增
export function isgetCloudadd(params){
    return http.post(PRE_FIX + 'AccessRecords/Addcabinet',params)
}
//云柜存储新增律师信息
export function isgetlawyer(params){
    return http.get(PRE_FIX + 'AccessRecords/Gethqlsxx',params)
}
//云柜存储修改
export function isupdetlawyer(params){
    return http.post(PRE_FIX + 'AccessRecords/Updatecabinet',params)
}
//云柜存储删除
export function isdeletlawyer(params){
    return http.post(PRE_FIX + 'AccessRecords/Deletecabinet',params)
}

//xhj  云柜存储记录
//云柜分页查询
export function getCloudcabinet(params){
    return http.post(PRE_FIX + 'Hearing/Querycabinet',params)
}
//预约数据统计
export function getTjilist(params){
    return http.post(PRE_FIX + 'Common/querystatistics',params)
}

//阅卷信息统计
export function countReadStatusByTime(params){
    return http.get(PRE_FIX + 'Lawyer/CountReadStatusByTime',params)
}

//查询 根据id 分配办理人 
export function UpdateUserSList (params) {
    return http.post(PRE_FIX + 'Common/Updateuser', params)
}

//查询 当前单位下所有用户 
export function getUserinfosList (params) {
    return http.get(PRE_FIX + 'system/QueryUserlist', params)
}
//待办事项  id 查询预约信息
export function getYyList (params) {
    return http.get(PRE_FIX + 'Common/queryreunitId', params)
}



//首页进行中的任务
export function gethometask(params){
    return http.post(PRE_FIX + 'Common/queryreunit',params)
}

//首页外网任务统计
export function queryreunitsytj(params){
    return http.post(PRE_FIX + 'Common/queryreunitsytj',params)
}

//待办事项列表
export function getDaibList (params) {
    return http.post(PRE_FIX + 'Common/queryreunit', params)
}

//远程接待列表
export function getYcjdList (params) {
    return http.post(PRE_FIX + 'reception/QueryReception', params)
}

//视频举报列表
export function getVedioList (params) {
    return http.post(PRE_FIX + 'Video/QueryVideolistnew', params)
}
//视频举报状态
export function getstate (params){
    return http.get(PRE_FIX + 'Video/UpdateVideostate', params)
}

//视频举报文件
export function getVideoFile (params){
    return http.get(PRE_FIX + 'Common/GetImageFilesjson', params)
}

//律师服务列表
export function getLshiList (params) {
    return http.post(PRE_FIX + 'Lawyer/QueryLawyer', params)
}

//听证服务列表
export function getTingzList (params) {
    return http.post(PRE_FIX + 'Hearing/QueryHearing', params)
}


// 远程会见列表
export function getYuancList (params) {
    return http.post(PRE_FIX + 'Remote/QueryRemote', params)
}


//菜单修改
export function getChange (params) {
    return http.post(PRE_FIX + 'system/UpdateMenu', params)
}



//菜单删除
export function deleteMenu(params) {
    return http.post(PRE_FIX + 'system/DeleteMenu', params)
}

//菜单添加
export function getAddmenu (params) {
    return http.post(PRE_FIX + 'system/AddMenu', params)
}

//菜单查询分页
export function getPaging (params) {
    return http.post(PRE_FIX + 'system/QueryMenupage', params)
}

// 菜单树形查询
export function getTreeMenu (params) {
    return http.post(PRE_FIX + 'system/QueryMenuTree', params)
}

// 角色菜单IDs
export function getRoleMenuIds (params) {
    return http.post(PRE_FIX + 'system/QueryRoleMenu', params)
}

// 设置角色菜单权限 
export function setRoleMenuIds (params) {
    return http.post(PRE_FIX + 'system/AddRoleMenu', params)
}

// 获取当前用户权限菜单
export function getUserRoleMenu (params) {
    return http.get(PRE_FIX + 'system/GetroleMenu', params)
}


// 设备管理-获取列表
export function getDeviceMangeList (params) {
    return http.post(PRE_FIX + 'Device/QueryDevice', params)
}
// 设备新增
export function addDeviceMangeList (params) {
    return http.post(PRE_FIX + 'Device/AddDevice', params)
}
// 设备修改
export function updateDeviceMangeList (params) {
    return http.post(PRE_FIX + 'Device/UpdateDevice', params)
}
// 设备删除
export function deleteDeviceMangeList (params) {
    return http.post(PRE_FIX + 'Device/DeleteDevice', params)
}
//接待登记 分页列表
export function receptionregistration(params){
    return http.post(PRE_FIX + 'reception/QueryReception',params)
}
//接待登记修改
export function updateionregistration(params){
    return http.post(PRE_FIX + 'reception/UpdateReception',params)
}


// //账号登录
// export function login(params) {
//     return http.post(handleParams('/APP/Login'), params)
// }

// 账号登录登录
export function loginPhone (params) {
    return http.post(handleParams('APP/SystemLogin'), params)
}



// 启用用户
export function addUserqyInfo(params) {
    return http.post(PRE_FIX + 'system/EnableUser', params)
}

// 禁用用户
export function deletUserjyInfo(params) {
    return http.post(PRE_FIX + 'system/DisableUser', params)
}

// 获取用户信息
export function getUserInfo(params) {
    return http.post(PRE_FIX + 'system/QueryUser0', params)
}

// 新增用户
export function addUser(params) {
    return http.post(PRE_FIX + 'system/AddUser0', params)
}

// 编辑用户
export function editorUser(params) {
    return http.post(PRE_FIX + 'system/UpdateUser', params)
}

// 删除用户
export function deleteUser(params) {
    return http.post(PRE_FIX + 'system/DeleteUser', params)
}



//单位添加
export function getAddunit (params) {
    return http.post(PRE_FIX + 'system/AddUnit', params)
}
//单位修改
export function getChangeunit (params) {
    return http.post(PRE_FIX + 'system/UpdateUnit', params)
}
//单位删除
export function deleteunit(params) {
    return http.post(PRE_FIX + 'system/DeleteUnit', params)
}
//根据部门id查询下拉
export function getDepartmentlist(params) {
    return http.get(PRE_FIX + 'system/Querydepartment', params)
}

//部门查询id
export function getDepartmentpage(params) {
    return http.get(PRE_FIX + 'system/QueryDepart', params)
}
//部门查询分页
export function getDepartmentpagessss(params) {
    return http.post(PRE_FIX + 'system/QueryDepart', params)
}

//部门添加
export function addDepartment (params) {
    return http.post(PRE_FIX + 'system/AddDepart', params)
}
//部门修改
export function getChangedepartment (params) {
    return http.post(PRE_FIX + 'system/UpdateDepart', params)
}
//部门删除
export function deleteDepartment(params) {
    return http.post(PRE_FIX + 'system/DeleteDepart', params)
}
//根据单位查询角色列表
export function getRoles (params) {
    return http.post(PRE_FIX + 'system/QueryRole', params)
}
//新增角色
export function addPerson (params) {
    return http.post(PRE_FIX + 'system/AddRole', params)
}
//删除角色
export function deletePerson (params) {
    return http.post(PRE_FIX + 'system/DeleteRole', params)
}
//修改角色
export function updatePerson (params) {
    return http.post(PRE_FIX + 'system/UpdateRole', params)
}




//添加系统备份
export function addSystemBackups (params) {
    return http.post(PRE_FIX + '/systembackup/add', params)
}
//查看系统备份
export function getSystemBackups (params) {
    return http.post(PRE_FIX + '/systembackup/get-page', params)
}
//删除系统备份
export function deleteSystemBackups (params) {
    return http.delete(PRE_FIX + '/systembackup/delete', params)
}
//还原系统备份
export function reductionSystemBackups (params) {
    return http.get(PRE_FIX + '/systembackup/getreduction', params)
}



// 查询单位树形
export function getSupervisionUnitList (params) {
    return http.post(PRE_FIX + 'system/QueryUnitTree', params)
}
// 查询下拉单位
export function getfile (params) {
    return http.post(PRE_FIX + 'system/QueryUnitlist', params)
}


// 律师管理
export function getLawyerList (params) {
    return http.post(PRE_FIX + 'system/QueryUser1', params)
}

export function lawyerAudit (params) {
    return http.get(PRE_FIX + 'system/Examine', params)
}

// 卷宗分配
export function getOrderInfo (params) {
    return http.post(PRE_FIX + 'system/QueryUser1', params)
}

//律师服务获取二维码
export function obtainQRcode (params) {
    return http.get(PRE_FIX + 'Lawyer/EncodeData', params)
}

 
//律师服务审核状态修改

export function lawyerStates (isparams) {
    return http.get(PRE_FIX + 'Lawyer/UpdateLawyerstate', isparams)
}



// 从2.0系统查询案件信息
export function QueryCaseFromtwenty (isparams) {
    return http.get(PRE_FIX + 'Lawyer/QueryCaseFromtwenty', isparams)
}

// 从2.0系统查询卷宗信息
export function QueryFileFromtwenty (isparams) {
    return http.get(PRE_FIX + 'Lawyer/QueryFileFromtwenty', isparams)
}
// 用户管理重置密码20240329
export function ResetUser (isparams) {
    return http.post(PRE_FIX + 'system/resettingUser', isparams)
}

// 信访管理新增刑事20240402
export function addxsssdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/AddOrUpdateCriminalComplaint', isparams)
}
// 信访管理新增控告申诉20240402
export function addkgssdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/AddComplaint', isparams)
}
// 信访业务管理分页20240402
export function xfywdata (isparams) {
    return http.post(PRE_FIX + 'Petition/Query', isparams)
}
// 信访业务管理新增20240402
export function addxfywdata (isparams) {
    return http.post(PRE_FIX + 'Petition/Add', isparams)
}
// 信访业务管理修改20240402
export function updxfywdata (isparams) {
    return http.post(PRE_FIX + 'Petition/Update', isparams)
}
// 信访业务管理修改20240402
export function delxfywdata (isparams) {
    return http.post(PRE_FIX + 'Petition/Delete', isparams)
}
// 信访业务分页20240402
export function xflistdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/Query', isparams)
}
// 信访控告新增删除20240402
export function addxfkgdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/AddOrUpdateComplaint', isparams)
}
// 信访民事新增20240402
export function addmsssdata (isparams,type) {
    return http.post(PRE_FIX + 'PetitionInfo/AddOrUpdateCivilComplaint?type='+type, isparams)
}
// 信访删除20240402
export function dellistdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/Delete', isparams)
}
// 信访删除20240402
export function addgjpcdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/AddOrUpdateStateCompensation', isparams)
}
// 信访详情20240403
export function xqdata (isparams) {
    return http.get(PRE_FIX + 'PetitionInfo/GetById', isparams)
}
// 信访审核20240407
export function shdata (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/Statues', isparams)
}
// 普法宣传管理分页20240407
export function pfxcdata (isparams) {
    return http.post(PRE_FIX + 'PopularizePropagate/Query', isparams)
}
// 普法宣传修改20240407
export function pfxcupde (isparams) {
    return http.post(PRE_FIX + 'PopularizePropagate/Update', isparams)
}
// 挂号管理分页20240407
export function ghdata (isparams) {
    return http.post(PRE_FIX + 'Register/Query', isparams)
}
// 挂号管理分页20240407
export function ghdelet (isparams) {
    return http.post(PRE_FIX + 'Register/Delete', isparams)
}
// 挂号20240407
export function ghcall (isparams) {
    return http.get(PRE_FIX + 'Register/Call', isparams)
}
// 挂号20240407
export function pftype (isparams) {
    return http.get(PRE_FIX + 'PopularizePropagate/SetStatus', isparams)
}
// 挂号20240407
export function xftype (isparams) {
    return http.get(PRE_FIX + 'Petition/SetStatus', isparams)
}
// 司法救助新增编辑20240410
export function sfjzaddorupdet (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/AddOrUpdateJudicialAid', isparams)
}
// 司法救助新增编辑20240410
export function lajdaddorupdet (isparams) {
    return http.post(PRE_FIX + 'PetitionInfo/AddOrUpdateFilingSupervision', isparams)
}
// 普法宣传新增20240410
export function pfadd (isparams) {
    return http.post(PRE_FIX + 'PopularizePropagate/Add', isparams)
}
// 普法宣传新增20240410
export function pfdelet (isparams) {
    return http.post(PRE_FIX + 'PopularizePropagate/Delete', isparams)
}
// 信访管理新增20240417
export function xfadd (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswerResult/Add', isparams)
}
// 信访管理新增20240417
export function xflist (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswerResult/Query', isparams)
}
// 信访管理删除20240417
export function xflistdele (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswerResult/Delete', isparams)
}
// 信访管理修改20240417
export function xflistupdate (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswerResult/Update', isparams)
}
// 图片转换20240418
export function picarr (isparams) {
    return http.post(PRE_FIX + 'Common/GetNewImageFiles', isparams)
}
// 信访详情20240418
export function addxqlist (isparams) {
    return http.get(PRE_FIX + 'QuestionAnswerResult/GetById', isparams)
}
// 信访审核20240418
export function xqlistsh (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswerResult/Audit', isparams)
}
// 接口管理分页20240422
export function jklist (isparams) {
    return http.post(PRE_FIX + 'Interface/Query', isparams)
}
// 接口管理删除20240422
export function jkdel (isparams) {
    return http.post(PRE_FIX + 'Interface/Delete', isparams)
}
// 接口管理添加20240422
export function jkadd (isparams) {
    return http.post(PRE_FIX + 'Interface/Add', isparams)
}
// 接口管理修改20240422
export function jkupde (isparams) {
    return http.post(PRE_FIX + 'Interface/Update', isparams)
}
// 接口管理授权20240422
export function jksq(isparams) {
    return http.get(PRE_FIX + 'Interface/Updatesq', isparams)
}

  //  #region 'QuestionAnswer 问答'
export function Api_QuestionAnswerAdd (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswer/Add', isparams)
}
export function Api_QuestionAnswerDelete (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswer/Delete', isparams)
}
export function Api_QuestionAnswerUpdate (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswer/Update', isparams)
}
export function Api_QuestionAnswerQuery (isparams) {
    return http.post(PRE_FIX + 'QuestionAnswer/Query', isparams)
}
  //  #endregion