import { createStore } from 'vuex'

export default createStore({
  state: {
    avataUrl: '',
    noticeInfoList: [],
    isOpenNotice: false,
    diaSupermanDia: false,
    custodianTableData: [],
    pageIndex: 1,
    pageSize: 10,
    total: 0
  },
  getters: {
  },
  mutations: {
    SET_AVATAURL_IMG(state, payload) {
      state.avataUrl = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
    SET_NOTICE_INFO_LIST(state, payload) {
      state.noticeInfoList = payload;

    },
    SET_OPEN_NOTICE(state, payload) {
      state.isOpenNotice = payload;
    },
    SET_DIA_SUPER_MAN_DIA(state, payload) {
      state.diaSupermanDia = payload;
    },
    SET_CUSTODIAN_TABLE_DATA(state, payload) {
      state.custodianTableData = payload;
    },
    SET_PAGE_SIZE(state, payload) {
      state.pageSize = payload;
    },
    SET_PAGE_INDEX(state, payload) {
      state.pageIndex = payload;
    },
    SET_TOTAL(state, payload) {
      state.pageIndex = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
