import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import Head from './components/head.vue'
import './style/index.less'
// import Echarts from 'echarts';
import locale from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

 

window._AMapSecurityConfig = {
  securityJsCode: '27514125afca3fd10bd1a43c260bc482',
}

initAMapApiLoader({
  key:
    "882b4c1b088a24f64998bab7521ce50e",
  plugin: [
    "AMap.Autocomplete",
    //输入提示插件
    "AMap.PlaceSearch",
    //POI搜索插件
    "AMap.Scale",
    //右下角缩略图插件 比例尺
    "AMap.OverView",
    //地图鹰眼插件
    "AMap.ToolBar",
    //地图工具条
    "AMap.MapType",
    //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor",
    //编辑 折线多，边形
    "AMap.CircleEditor",
    //圆形编辑器插件
    "AMap.Geolocation"
    //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion:
    "1.0"
});
const app = createApp(App)
// app.component('Head', Head)


app.component('qr-code')


app.use(store)
  .use(ElementPlus)
  .use(locale)
  .use(VueAMap)
  .use(router).mount('#app')

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
